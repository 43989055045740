<template>
  <v-container
    class="d-flex flex-column align-center justify-center pa-3"
    style="overflowY: auto; height: 100%; width: 100%"
    fluid
  >
    <v-img
      contain
      width="100%"
      max-height="70%"
      src="@/assets/Forbidden.svg"
      content-class="d-flex justify-end"
    />
    <div class="d-flex flex-column align-center justify-center">
      <div class="text-h5 font-weight-bold text-uppercase neutralPrimary--text mb-3">
        {{ $t('deconve.forbidden.accessDenied') }}
      </div>
      <span
        class="text-body-1 neutral--text"
        style="text-align: center"
      >
        {{ $t('deconve.forbidden.accessDeniedMessage') }}
      </span>
    </div>
    <rectangle-button
      class="d-flex mt-6"
      style="width: inherit; max-width: 400px"
      color="primary"
      @clicked="tryAgain"
    >
      {{ $t('deconve.retry') }}
    </rectangle-button>
    <rectangle-button
      class="d-flex mt-3"
      style="width: inherit; max-width: 400px"
      color="neutral"
      outlined
      @clicked="goBack"
    >
      {{ $t('deconve.goBack') }}
    </rectangle-button>
  </v-container>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import RectangleButton from '@/components/RectangleButton.vue';

export default {
  name: 'Forbidden',
  components: { RectangleButton },
  data() {
    return {
      fromRoute: null,
    };
  },
  methods: {
    goBack() {
      if (this.fromRoute?.name) {
        this.$router.go(-1);
      } else {
        this.$router.push({ path: '/' });
      }
    },
    tryAgain() {
      if (this.$route.query.redirect) {
        window.location.assign(this.$route.query.redirect);
      } else {
        window.location.reload();
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.fromRoute = from;
    });
  },
};
</script>
